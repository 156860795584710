
import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
// import { MatDialogRef } from '@angular/material';
import { UploadService } from '../services/upload.service';
import { forkJoin, of } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { Photo } from '@/models/photo';
import { PhotoService } from '@/services/photo.service';
import { MessageService } from '@/services/message.service';
import { NGXLogger } from 'ngx-logger';
import { AppConfigService } from '@/services/app-config.service';
import { LanguageService } from '@/services/language.service';

@Component({
  selector: 'app-uploaddialog',
  templateUrl: './uploaddialog.component.html',
  styleUrls: ['./uploaddialog.component.scss'],
  standalone: false
})
export class UploaddialogComponent implements OnInit {
  @ViewChild('fileInput', { read: true, static: true }) fileInput: ElementRef; // sfrezefo modif 7 to 8 ng updat ?
  @Input() episodeId: number;
  @Input() numero: number;

  progress;
  canBeClosed = true;
  primaryButtonText = 'Upload';
  showCancelButton = true;
  uploading = false;
  uploadProgress: number;
  size_x: number;
  size_y: number;
  preview: string;

  uploadSuccessful = false;
  file1: File;
  filename: string;

  public files: Set<File> = new Set();

  constructor(/* public dialogRef MatDialogRef<UploaddialogComponent>,*/
    private router: Router,
    private appconfigService: AppConfigService,
    private route: ActivatedRoute,
    public uploadService: UploadService,
    private languageService: LanguageService,
    private messageService: MessageService,
    private photoService: PhotoService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    const episode_id = +this.route.snapshot.paramMap.get('episode_id');
    this.logger.debug('upload dialog ' + episode_id)
    if (episode_id) {
      this.episodeId = episode_id;
    }
  }

  onFilesAdded(event) {

    const file: File = event.target.files[0];
    if (file) {
      this.filename = file.name;
      const formData = new FormData();
      formData.append("thumbnail", file);
      // const upload$ = this.http.post("/api/thumbnail-upload", formData);
      // upload$.subscribe();
      this.file1 = file;
      this.logger.debug('onFileChange this fileName : ', this.file1.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        this.preview = reader.result as string;
        // this.logger.debug('onFileChddange this preview : ', this.preview);
        const image = new Image();
        image.src = e.target.result as string;
        image.onload = () => {
          // const {
          //   height,
          //   width
          // } = image;
          this.size_y = image.height;
          this.size_x = image.width;
          this.logger.debug('height and width : ' + image.height + ' ' + image.width)
          return true;
        };
      };


      reader.readAsDataURL(file);
    }

    for (const key in event.target.files) {
      if (!isNaN(parseInt(key, 10))) {
        this.files.add(event.target.files[key]);
      }
    }

    // console.log('uploadEpisodePhoto onFilesAdded ');
    // const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    // this.file1 = this.fileInput.nativeElement.files[0];
    // console.log('uploadEpisodePhoto onFilesAdded file:' + this.file1);
    // console.log('uploadEpisodePhoto onFilesAdded filename:' + this.file1.name);

    // for (const key in files) {
    //   if (!isNaN(parseInt(key, 10))) {
    //     this.files.add(files[key]);
    //   }
    // }
  }

  addFiles() {
    console.log('  addFiles ');
    this.fileInput.nativeElement.click();
  }

  closeDialog() {
    // if everything was uploaded already, just close the dialog
    if (this.uploadSuccessful) {
      //  return this.dialogRef.close();
      this.logger.debug('upload dialog closeDialog uploadSuccessful true');
      this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/episodes/' + this.episodeId]);
    }
    else {


      // set the component state to "uploading"
      this.uploading = true;

      // start the upload and save the progress map
      // this.progress = 
      // this.uploadService.uploadEpisodePhoto(this.files, 1, 1);

      // const fileURL = this.uploadService.getBaseUrl();
      console.log('uploadEpisodePhoto file1:' + this.file1);
      const fileURL = this.uploadService.upload(this.file1);
      console.log('uploadEpisodePhoto fileURL:' + fileURL);
      // to track progress
      // const upload$ = this.http.post("/api/thumbnail-upload", formData, {
      //   reportProgress: true,
      //   observe: 'events'
      // })

      const photo: Photo = new Photo();
      photo.desc = 'desccccc ' + fileURL;
      photo.numero = this.numero;
      photo.episode = this.episodeId; // should be parameter for the service
      // photo.url = 'https://sapicafrou.blob.core.windows.net/uploads/' + fileURL;
      photo.url = '';
      photo.photocontainer = this.appconfigService.config.STORAGE_CONTAINER_NAME;
      photo.photoname = fileURL;
      photo.size_x = this.size_x;
      photo.size_y = this.size_y;


      this.photoService.addPhoto(photo)
        .subscribe(newphoto => {
          this.log('uploadService djphoto added id: ' + newphoto.id + ' desc :' + newphoto.desc);
        });
      this.log('uploadService djphoto added ' + photo.desc);
      /*
          this.progress = this.uploadService.upload(this.files);
          console.log(this.progress);
          for (const key in this.progress) {
            this.progress[key].progress.subscribe(val => console.log(val));
          }
  
          // convert the progress map into an array
          let allProgressObservables = [];
          for (let key in this.progress) {
            allProgressObservables.push(this.progress[key].progress);
          }
       */
      // Adjust the state variables

      // to have progress bar
      // this.uploadSub = upload$.subscribe(event => {
      //   if (event.type == HttpEventType.UploadProgress) {
      //     this.uploadProgress = Math.round(100 * (event.loaded / event.total));
      //   }
      // })
      // The OK-button should have the text "Finish" now
      this.primaryButtonText = 'Finish';

      // The dialog should not be closed while uploading
      this.canBeClosed = false;
      // this.dialogRef.disableClose = true;

      this.showCancelButton = false;

      /*
          // When all progress-observables are completed...
          forkJoin(allProgressObservables).subscribe(end => {
            // ... the dialog can be closed again...
            this.canBeClosed = true;
            this.dialogRef.disableClose = false;
  
            // ... the upload was successful...
            this.uploadSuccessful = true;
  
            // ... and the component is no longer uploading
            this.uploading = false;
          }); */

      // sfrezefo
      this.canBeClosed = true;
      // this.dialogRef.disableClose = false;
      this.uploadSuccessful = true;
      this.uploading = false;
      // end sfrezefo

    }

  }
  private log(message: string) {
    this.messageService.add('UploadService: ' + message);
  }

}
