import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Episode } from '../models/episode';
import { EpisodeService } from '../services/episode.service';
import { AuthorService } from '@/services/author.service';
import { Author } from '@/models/author';
import { AuthenticationService } from '@/_services';
import { Observable, Subscription } from 'rxjs';
import { ImageService } from '@/services/image.service';
import { PhotoService } from '@/services/photo.service';
import { NGXLogger } from 'ngx-logger';
import * as e from 'cors';
import { SharingDialogComponent } from '../serie-detail/sharing-dialog/sharing-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FullscreenImageDialogComponent } from '@/fullscreen-image-dialog/fullscreen-image-dialog.component';
import { DeleteEpisodeDialogComponent } from '@/delete-episode-dialog/delete-episode-dialog.component';
import { Serie } from '@/models/serie';
import { Meta, Title } from '@angular/platform-browser';
import { LanguageService } from '@/services/language.service';
import { AppConfigService } from '@/services/app-config.service';


@Component({
  selector: 'app-episode-detail',
  templateUrl: './episode-detail.component.html',
  styleUrls: ['./episode-detail.component.scss'],
  standalone: false
})
export class EpisodeDetailComponent implements OnInit {

  @Input() episode: Episode;
  display_comment: boolean = true;
  episode$: Observable<Episode>;
  author: Author;
  author$: Observable<Author>;
  fullscreenSubscription: Subscription;
  fullscreen: boolean;

  isbookmarkedbyme$: Observable<boolean>
  isbookmarkedbyme: boolean
  islikedbyme: boolean;
  iscommentedbyme: boolean;
  nblikes: number;
  nbcomments: number;
  nbbookmarks: number;
  sharedurl: string;

  constructor(
    private ref: ChangeDetectorRef,
    private appconfigService: AppConfigService,
    private route: ActivatedRoute,
    private episodeService: EpisodeService,
    private photoService: PhotoService,
    private authorService: AuthorService,
    public authenticationService: AuthenticationService,
    private imageService: ImageService,
    private languageService: LanguageService,
    public dialog: MatDialog,
    private router: Router,
    private logger: NGXLogger,
    private meta: Meta,
    private title: Title,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.logger.debug('episode component init');

    // this.sharedurl = window.location.href;
    this.sharedurl = this.appconfigService.config.frontendUrl + this.router.url + '/';


    const id = +this.route.snapshot.paramMap.get('id');
    const urllang = this.route.snapshot.paramMap.get('lang');
    this.languageService.changeLanguage(urllang)
    this.logger.debug('EpisodeDetailComponent urllang : ' + urllang)

    this.episode$ = this.getEpisode(id);
    // this.author$ = this.getEpisodeAuthor(id);
    this.episode$.subscribe(episode => {
      this.episode = episode;
      this.title.setTitle(episode.title)
      this.meta.removeTag('name="title"');
      this.meta.removeTag('name="description"');
      this.meta.addTags([{ name: 'title', content: episode.title },
      { name: 'description', content: episode.desc }
      ]);
      this.meta.removeTag('name="og:title"');
      this.meta.removeTag('name="og:description"');
      this.meta.removeTag('name="og:type"');
      this.meta.removeTag('name="og:locale"');
      this.meta.removeTag('name="og:site_name"');
      this.meta.removeTag('name="og:url"');
      this.meta.removeTag('name="og:image"');
      this.meta.addTags([
        { name: 'og:title', content: episode.title },
        { name: 'og:description', content: episode.desc },
        { name: 'og:type', content: "article" },
        { name: 'og:locale', content: this.languageService.getCurrentLanguage() },
        { name: 'og:site_name', content: '@picafrou' },
        { name: 'og:url', content: this.sharedurl },
        { name: 'og:image', content: this.imageService.getphotourl(episode.photocontainer, episode.photoname) }
      ]);

      this.meta.removeTag('name="twitter:title"');
      this.meta.removeTag('name="twitter:description"');
      this.meta.removeTag('name="twitter:card"');
      this.meta.removeTag('name="twitter:site"');
      this.meta.removeTag('name="twitter:url"');
      this.meta.removeTag('name="twitter:image"');
      this.meta.addTags([
        { name: 'twitter:title', content: episode.title },
        { name: 'twitter:description', content: episode.desc },
        { name: 'twitter:card', content: "summary" },
        { name: 'twitter:site', content: "@picafrou" },
        { name: 'twitter:url', content: this.sharedurl },
        { name: 'twitter:image', content: this.imageService.getphotourl(episode.photocontainer, episode.photoname) }
      ]);
      if (this.authenticationService.isLoggedIn()) {
        // this.episodeService.isbookmarkedbyme(id).subscribe(data => this.isbookmarkedbyme = data);
        this.isbookmarkedbyme = this.episode.isbookmarkedbyme;
        // this.episodeService.islikedbyme(id).subscribe(data => this.islikedbyme = data);
        this.islikedbyme = this.episode.islikedbyme;
        // this.episodeService.iscommentedbyme(id).subscribe(data => this.iscommentedbyme = data);
        this.iscommentedbyme = this.episode.iscommentedbyme;
      }
      // this.episodeService.nblikes(id).subscribe(data => this.nblikes = data);
      this.nblikes = this.episode.nb_likes;
      // this.episodeService.nbcomments(id).subscribe(data => this.nbcomments = data);
      this.nbcomments = this.episode.nb_comments;
      // this.episodeService.nbbookmarks(id).subscribe(data => this.nbbookmarks = data);
      this.nbbookmarks = this.episode.nb_bookmarks;

    });



    this.fullscreenSubscription = this.photoService.fullscreen.subscribe(fs => {
      // this.ref.markForCheck();
      this.logger.debug('episode component changefullscreen value  ' + fs);
      this.fullscreen = fs;
      // this.ref.markForCheck();
      this.ref.detectChanges();
      this.logger.debug('episode component changefullscreen value after detect change ' + fs);
    });
    this.logger.debug('episode coomponent init end');
  }

  getEpisode(id: number): Observable<Episode> {
    return this.episodeService.getEpisode(id);
  }

  getEpisodeAuthor(id: number): Observable<Author> {
    return this.episodeService.getEpisodeAuthor(id);
  }

  goBack(): void {
    this.location.back();
  }

  save(): void {
    this.episodeService.updateEpisode(this.episode)
      .subscribe(() => this.goBack());
  }

  like(episode: Episode): void {
    this.episodeService.likeEpisode(episode).subscribe();
    this.islikedbyme = !this.islikedbyme;
    if (this.islikedbyme)
      this.nblikes += 1;
    else
      this.nblikes -= 1;
  }

  bookmark(episode: Episode): void {
    this.episodeService.bookmarkEpisode(episode).subscribe();
    this.isbookmarkedbyme = !this.isbookmarkedbyme;
    if (this.isbookmarkedbyme)
      this.nbbookmarks += 1;
    else
      this.nbbookmarks -= 1;;
  }

  openShareDialog(): void {
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  openfullscreenimageDialog() {
    this.imageService.ogphotourl(this.episode.photocontainer, this.episode.photoname).subscribe(
      x => {
        const dialog_ref = this.dialog.open(FullscreenImageDialogComponent, {
          panelClass: ['full-screen'],
          data: { imgUrl: x },
          // data: { imgUrl: this.imageService.ogphotourl(this.author.photocontainer, this.author.photoname) },
        });
        dialog_ref.afterClosed().subscribe(result => { });
      });
  }

  deleteEpisodeDialog(episode_id: number): void {
    this.logger.debug('deleteEpisodeDialog ' + episode_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.logger.debug('deleteEpisodeDialog iepisode ' + JSON.stringify(this.episode));

    dialogConfig.data = {
      id: episode_id,
    };
    const dialogRef = this.dialog.open(DeleteEpisodeDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');

      this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/series/' + (this.episode.serie as Serie).id]);
    });
  }
}
