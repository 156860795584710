import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '@/_models';
import { AuthenticationService } from '@/_services';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LanguageService } from '@/services/language.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  standalone: false
})
export class LogoutComponent implements OnInit, OnDestroy {
  currentUser: User;
  isLoggedIn: boolean;
  currentUserSubscription: Subscription;
  title = 'picafrou';

  constructor(
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private router: Router
  ) {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.currentUserSubscription.unsubscribe();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/login']);
  }
}
