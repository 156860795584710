<form [formGroup]="serieForm"
      class="serie-form-flex"
      (ngSubmit)="onSubmit()">
    <mat-card appearance="outlined"
              class="my-card">
        <mat-card-title>
            {{pageTitle}}
        </mat-card-title>
        <mat-card-content>
            <mat-form-field class="full-width">
                <mat-label>Name</mat-label>
                <input matInput
                       matTooltipPosition="above"
                       matTooltip="Name of the Serie"
                       [matTooltipShowDelay]="100"
                       [matTooltipHideDelay]="300"
                       type="text"
                       id="nameInput"
                       placeholder="Name"
                       formControlName='name'>
                <mat-error *ngIf="serieForm.controls.name.invalid">name is required</mat-error>
            </mat-form-field>
            <!-- <div *ngIf="serieForm.name.errors?.['required']">Name is required.</div> -->

            <!-- <mat-form-field class="full-width">
                <mat-label>Desc</mat-label>
                <input matInput
                       type="text"
                       id="descInput"
                       placeholder="Desc"
                       formControlName='desc'>
            </mat-form-field> -->
            <mat-form-field class="full-width"
                            appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput
                          formControlName="desc"
                          placeholder="Description"
                          cdkTextareaAutosize=""
                          [cdkAutosizeMinRows]="2"
                          [cdkAutosizeMaxRows]="5"
                          [maxlength]="200">
                       </textarea>
                <mat-error *ngIf="serieForm.controls.desc.invalid">desc is required</mat-error>

            </mat-form-field>

            <!-- <mat-form-field class="full-width">
                <mat-label>Category</mat-label>
                <input matInput
                       type="number"
                       id="categoryInput"
                       placeholder="Category"
                       formControlName='category'>
            </mat-form-field> -->
            <mat-form-field class="full-width"
                            appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select formControlName="category"
                            placeholder="Select category">
                    <mat-option *ngFor="let cat of categories"
                                [value]="cat.id">{{cat.name}}</mat-option>
                    <!-- <mat-option [value]=8>Romance</mat-option>
                    <mat-option [value]=9>Romance</mat-option>
                    <mat-option [value]=10>Romance</mat-option>
                    <mat-option [value]=11>Drama</mat-option>
                    <mat-option [value]=12>Comic</mat-option> -->
                </mat-select>
            </mat-form-field>

            <!-- 
            <mat-form-field class="full-width">
                <mat-label>status</mat-label>
                <input matInput
                       type="number"
                       id="statusInput"
                       placeholder="STATUS"
                       [formControl]="serieForm.controls['status']">
            </mat-form-field> -->
            <!-- <label id="example-radio-group-label">Status</label> -->
            <div class="status">
                <label id="example-radio-group-label"
                       class="example-radio-group">Status</label>

                <mat-radio-group aria-labelledby="example-radio-group-label"
                                 class="example-radio-group"
                                 formControlName="status"
                                 value='1'>
                    <!-- <mat-label>status</mat-label> -->
                    <!-- <span style="margin-right:3%;">Status : </span> -->
                    <mat-radio-button class="example-radio-button"
                                      [value]=1>
                        draft
                    </mat-radio-button>
                    <mat-radio-button class="example-radio-button"
                                      [value]=3>
                        published
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <!--

      <mat-form-field class="full-width">
        <mat-label>pubdate</mat-label>
        <input matInput type="date" id="pubdateInput" placeholder="DATE" [formControl]="serieForm.controls['pub_date']">
      </mat-form-field>
-->
            <div>
                <!-- <mat-label>file</mat-label> -->
                <!-- <img mat-card-image
                     class="serieimg"
                     *ngIf="serie && (imageService.ogphotourl(serie.photocontainer,serie.photoname)|async) as imgurl"
                     [src]="imgurl"
                     alt="desc 1"> -->
                <input type="file"
                       accept="image/png,image/jpeg,image/gif"
                       id="fileInput"
                       (change)="onFileChange($event)"
                       placeholder="serie picture"
                       formControlName="file" />

                <mat-error *ngIf="serieForm.controls.file.invalid">file is required</mat-error>
                <div *ngIf="preview && preview !== ''"> <img class="imgpreview"
                         [src]="preview">
                </div>
                <!-- <p *ngIf="serieForm.controls.file.errors?.required"
                   style="color: red">This field is required!</p> -->
                <!-- <div>: {{fileName}} </div> -->
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button
                    matTooltipPosition="above"
                    matTooltip="Cancel Serie creation"
                    [matTooltipShowDelay]="500"
                    [matTooltipHideDelay]="500"
                    color="accent"
                    type="button"
                    (click)="onCancel()">cancel</button>
            <button mat-raised-button
                    matTooltipPosition="above"
                    matTooltip="Validate Serie creation"
                    [matTooltipShowDelay]="500"
                    [matTooltipHideDelay]="500"
                    color="primary"
                    [disabled]="serieForm.invalid"
                    type="submit">{{buttonText}}</button>
        </mat-card-actions>

    </mat-card>
</form>