import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { User } from '@/models/user';
import { MessageService } from './message.service';
import { environment } from '../../environments/environment';
import { Page, queryPaginated, queryPaginatedAll, queryPaginatedAllLimit } from '../pagination';
// import { AuthenticationService } from '../_services';
import { AppConfigService } from './app-config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlService } from './url.service';
import { NGXLogger } from 'ngx-logger';
import { Comment } from '../models/comment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private urlService: UrlService,
    private logger: NGXLogger) {
    // private authenticationService: AuthenticationService
  }

  private httpOptions = {
    //  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Userization': 'JWT ' + AuthenticationService.getJWTToken() })
  };



  registerByEmailWithPseudo(user: User) {
    return this.http.post(this.urlService.getRootUrl() + '/en/dj-rest-auth/registration/',
      { email: user.email, password1: user.password, password2: user.password });
    //     .pipe(
    //         this.getByMail(user.email).subscribe(

    // )
    // this.setPseudoById(user.id));
  }

  register(user: User) {
    //       "email=serge-frezefond@orange.fr&password1=manager12&password2=manager12"
    //       http://localhost:8000/en/rest-auth/registration/
    // console.log('UserService register ' + user.email + ' ' + user.password);
    return this.http.post(this.urlService.getRootUrl() + '/en/dj-rest-auth/registration/',
      { email: user.email, password1: user.password, password2: user.password, pseudo: user.pseudo });
    // .pipe(tap(this.http.put(this.usersUrl + `${user.id}` + '/', user)));

    //  JSON.stringify(user));
    // user.token = '';
    // return (of(user));
  }
  getAll() {
    return this.http.get<User[]>(this.urlService.getUserUrl());
  }

  getByMail(email: string): Observable<User> {
    return queryPaginatedAllLimit<User>(this.http, this.urlService.getUserUrl() + '?email=' + email).pipe(
      map(events => {
        return events.results[0];
      }));
  }

  getByPseudo(pseudo: string): Observable<User> {
    return queryPaginatedAllLimit<User>(this.http, this.urlService.getUserUrl() + '?pseudo=' + pseudo).pipe(
      map(events => {
        return events.results[0];
      }));
  }

  getById(id: number): Observable<User> {
    return this.http.get<User>(this.urlService.getUserUrl() + id);
    //        return this.http.get<User>(`${API_URL}/users/${id}`);
  }

  setPseudoById(id: number, pseudo: string): Observable<any> {
    console.log('setPseudoById ' + id + ' ' + pseudo)
    return this.http.patch(`${this.urlService.getUserUrl()}${id}/`,
      { id: id, pseudo: pseudo });
  }

  /** GET users from the server */
  getUsers(): Observable<User[]> {
    console.log('userService getUsers ');
    return this.http.get<User[]>(this.urlService.getUserUrl(), this.httpOptions)
      .pipe(
        tap(users => this.log(`fetched users`)),
        catchError(this.handleError('getUsers', []))
      );
  }

  getUsersList(urlOrFilter?: string | object): Observable<Page<User>> {
    console.log('userService getUsersList ');
    return queryPaginated<User>(this.http, this.urlService.getUserUrl(), urlOrFilter);
  }

  getUsersListAll(): Observable<Page<User>> {
    return queryPaginatedAll<User>(this.http, this.urlService.getUserUrl());
  }

  /** GET user by id. Return `undefined` when id not found */
  getUserNo404<Data>(id: number): Observable<User> {
    const url = `${this.urlService.getUserUrl()}?id=${id}/`;
    return this.http.get<User[]>(url)
      .pipe(
        map(users => users[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} user id=${id}`);
        }),
        catchError(this.handleError<User>(`getUser id=${id}`))
      );
  }

  /** GET user by id. Will 404 if id not found */
  getUser(id: number): Observable<User> {
    const url = `${this.urlService.getUserUrl()}${id}/`;
    // console.log('userService getUser ');
    // console.log('userService dynamic config ' + this.appconfigService.config);
    // console.log('userService dynamic config resourceServerA:' + this.appconfigService.config.resourceServerA);



    return this.http.get<User>(url, this.httpOptions).pipe(
      tap(_ => this.log(`fetched user id=${id}`)),
      catchError(this.handleError<User>(`getUser id=${id}`))
    );
  }

  /** GET number of episodes for user id */
  /*
  getUserMaxEpisode(id: number): Observable<Number> {
    const url = `${this.usersUrl}${id}/getmax/`;
    console.log('userService getUserMaxEpisode ');

    return this.http.get<Number>(url, this.httpOptions).pipe(
      tap(_ => this.log(`fetched user id=${id}`)),
      catchError(this.handleError<User>(`getUser id=${id}`))
    );
  }
  */


  /* GET users whose name contains search term */
  searchUsers(term: string): Observable<User[]> {
    if (!term.trim()) {
      // if not search term, return empty user array.
      return of([]);
    }
    return this.http.get<User[]>(`${this.urlService.getUserUrl()}?name=${term}`).pipe(
      tap(_ => this.log(`found users matching "${term}"`)),
      catchError(this.handleError<User[]>('searchUsers', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new user to the server */
  addUser(user: User): Observable<User> {

    console.log('addUser: ', user);
    // user.desc = 'aaaa';
    // user.user = 2;
    console.log('addUser url: ', this.urlService.getUserUrl());
    return this.http.post<User>(this.urlService.getUserUrl(), user, this.httpOptions).pipe(
      tap((xuser: User) => this.log(`added user w/ id=${xuser.id}`)),
      catchError(this.handleError<User>('addUser'))
    );
  }

  /** DELETE: delete the user from the server */
  deleteUser(user: User | number): Observable<User> {
    const id = typeof user === 'number' ? user : user.id;
    const url = `${this.urlService.getUserUrl()}${id}/`;

    return this.http.delete<User>(url, this.httpOptions).pipe(
      tap(_ => this.log(`deleted user id=${id}`)),
      catchError(this.handleError<User>('deleteUser'))
    );
  }

  /** PUT: update the user on the server */
  partialUpdateUser(user: User): Observable<any> {
    return this.http.patch(this.urlService.getUserUrl() + user.id + '/', user, this.httpOptions).pipe(
      tap(_ => this.log(`partial updated user id=${user.id}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }
  /** PUT: update the user on the server */
  updateUser(user: User): Observable<any> {
    return this.http.put(this.urlService.getUserUrl(), user, this.httpOptions).pipe(
      tap(_ => this.log(`updated user id=${user.id}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }


  update(user: User) {
    return this.http.put(this.urlService.getUserUrl() + `${user.id}` + '/', user);
  }

  delete(id: number) {
    return this.http.delete(this.urlService.getUserUrl() + id);
  }
  // to be fixed to avoid  circular DI issue
  // followUser(user: User): Observable<any> {
  //   console.log('userService subscribe: userid ' + this.authenticationService.currentUserValue.id);

  //   return this.http.post(this.followsUrl, {
  //     reader: this.authenticationService.currentUserValue.id,
  //     to_user: user.id
  //   }, this.httpOptions);
  // }


  // rateUser(user: User, rating: number): Observable<any> {
  //   console.log('userService rateUser: userid ${this.authenticationService.currentUserValue.id} ' +
  //     'rating ${rating}');

  //   return this.http.post(this.ratingsUrl, {
  //     reader: this.authenticationService.currentUserValue.id,
  //     user: user.id, value: rating
  //   }, this.httpOptions);
  // }


  isFollowedByMe(authorId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getUserUrl() + authorId + '/isfollowedbyme/', this.httpOptions);
  }

  nbFollowers(authorId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getUserUrl() + authorId + '/nbfollowers/', this.httpOptions);
  }

  iscommentedbyme(authorId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getUserUrl() + authorId + '/iscommentedbyme/', this.httpOptions);
  }

  nbcomments(authorId: number): Observable<number> {
    return this.http.get<number>(this.urlService.getUserUrl() + authorId + '/nbcomments/', this.httpOptions);
  }

  getUserComments(user_id: number): Observable<Comment[]> {
    return queryPaginatedAllLimit<Comment>(this.http, this.urlService.getUserUrl() + user_id + '/comments/?ordering=-id', 100)
      .pipe(
        map(events => {
          return events.results;
        }
        ),
        tap(episodes => this.logger.debug(`fetched episodes`)),
        catchError(this.handleError('getEpisodes', []))
      );
  }

  isEditor(authorId: number): Observable<boolean> {
    return this.http.get<boolean>(this.urlService.getUserUrl() + authorId + '/isEditor/');
  }

  hasRole(authorId: number, role: string): Observable<boolean> {
    let params = new HttpParams().set('role', role);
    return this.http.get<boolean>(this.urlService.getUserUrl() + authorId + '/hasRole/', { params: params });
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    // console.log('userService: handleError');
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a UserService message with the MessageService */
  private log(message: string) {
    this.messageService.add('UserService: ' + message);
  }
}
