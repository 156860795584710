<ng-container *ngIf="episode$ | async as episode">
    <div class="addbutton"
         *ngIf="isEditor">

        <!-- *ngIf="authenticationService.isOwner(episode.owner.id)">
*ngIf="authenticationService.isLoggedIn()" -->
        <div class="inlinebutton">
            <a mat-mini-fab
               title="generate images with included tex"
               (click)="genImages()">
                <mat-icon aria-label="generate images with included text">photo</mat-icon>
            </a>
        </div>
        <div class="inlinebutton">
            <a mat-mini-fab
               title="hide photo options"
               (click)="displayPhotoMenu=!displayPhotoMenu">
                <mat-icon aria-label="hide photo options">
                    <span class="material-symbols-outlined">
                        hide_source
                    </span>
                </mat-icon>
            </a>
        </div>
        <div class="inlinebutton">
            <a mat-mini-fab
               title="generate images with included text  for num"
               (click)="genphotosnumDialog()">
                <mat-icon aria-label="generate images with included text for num"
                          class="material-symbols-outlined"><span class="material-symbols-outlined">
                        123
                    </span></mat-icon>
            </a>
        </div>
    </div>
    <div class="addbutton">
        <div class="centeradd"
             *ngIf="(authenticationService.isOwner(episode.owner.id)) ">
            <button *ngIf="authenticationService.isLoggedIn()"
                    mat-raised-button
                    title="add photo to episode"
                    color="primary"
                    (click)="newPhotoDialog(episode.id)">
                <!-- routerLink="{{'/' + languageService.getActivateLanguage()}}/photos/new/{{episode_id}}"> -->
                <mat-icon aria-label="add photo to episode">add</mat-icon>
                add Photo
            </button>
        </div>
    </div>
</ng-container>
<ng-container *ngFor="let photo of (photos$ | async); ; let ind = index;">
    <!-- <ng-container *ngIf="photos"> -->
    <!-- <ng-container *ngFor="let photo of photos"> -->
    <div id="{{ 'divimage' + photo.id }}"
         class="image">
        <div *ngIf="(authenticationService.isOwner(episode.owner.id)) && (displayPhotoMenu)"
             id="{{ 'topbuttons' + photo.id }}"
             class="buttonoverlay">
            <button mat-icon-button
                    class="buttoncolor"
                    [routerLink]="['/' , languageService.getActivateLanguage() , 'editphoto' , photo.id, {episodeMode: 'edit'}]"
                    title="edit photo from episode">
                <!-- [routerLink]="['/editphoto/'+photo.id,{episodeMode: 'edit'}]" -->
                <!-- routerLink="{{'/' + languageService.getActivateLanguage()}}/editphoto/{{photo.id}}" -->
                <mat-icon aria-label="heart icon">edit</mat-icon>
            </button>
            <button mat-icon-button
                    class="buttoncolor"
                    title="delete photo from episode"
                    (click)="deletePhotoDialog(photo.id)">
                <mat-icon aria-label="delete photo">delete</mat-icon>
            </button>
            <button mat-icon-button
                    class="buttoncolor"
                    title="Move Up"
                    (click)="moveUp(photo)"
                    title="Move Up">
                <mat-icon aria-label="Move UpMove Up">arrow_upward</mat-icon>
            </button>
            <button mat-icon-button
                    class="buttoncolor"
                    title="Move Down"
                    (click)="moveDown(photo)"
                    title="Move Down">
                <mat-icon aria-label="Move Down Move Down">arrow_downward</mat-icon>
            </button>
            <button mat-icon-button
                    class="buttoncolor"
                    routerLink="{{'/' + languageService.getActivateLanguage()}}/photos/new/{{episode_id}}"
                    title="add photo to episode after current">
                <mat-icon aria-label="heart icon">add</mat-icon>
            </button>
        </div>
        <img *ngIf="(imageService.ogphotourl(photo.photocontainer,photo.photoname)|async) as imgurl"
             [src]="imgurl"
             id="{{ 'myimg' + photo.id }}"
             class="contentimage"
             alt="Smiley face">
        <div id="{{ 'textimg' + photo.id }}"
             class="textimg">
        </div>
        <div id="{{ 'textimgno' + photo.id }}"
             class="textimgno">
            {{photo.numero}}
        </div>
        <svg #mySvg
             id="{{ 'svg' + photo.id }}"
             xmlns="http://www.w3.org/2000/svg"
             style="position:absolute;top:0px;left:0px"
             zindex="9"
             width="100%"
             height="100%">
            <filter id='shadow'
                    color-interpolation-filters="sRGB">
                <feDropShadow dx="3"
                              dy="3"
                              stdDeviation="5"
                              flood-opacity="0.7" />
            </filter>
        </svg>
        <div class="interimage"
             id="{{ 'bottombuttons' + photo.id }}">
            <button mat-icon-button
                    class="buttoncolor"
                    (click)="likephoto(photo,ind)"
                    [disabled]="!authenticationService.isLoggedIn()"
                    title="like photo">
                <mat-icon aria-label="heart icon"
                          [matBadge]="nblikes[ind]"
                          matBadgePosition="after"
                          [ngStyle]="{'color': islikedbyme[ind] ? 'yellow' : 'blue'}">favorite</mat-icon>
            </button>
            <button mat-icon-button
                    class="buttoncolor"
                    (click)="display_comments[ind]=!display_comments[ind]"
                    [disabled]="!authenticationService.isLoggedIn()"
                    title="comment photo">
                <mat-icon aria-label="heart icon"
                          [matBadge]="nbcomments[ind]"
                          [ngStyle]="{'color': iscommentedbyme[ind] ? 'yellow' : 'blue'}"
                          matBadgePosition="after">comment</mat-icon>
            </button>
        </div>
    </div>
    <div class="interimage2">
        <div class="centerarrow">
            <span class="material-symbols-outlined material-icons md-48">
                arrow_downward
            </span>
        </div>
    </div>

    <!-- <app-comments [comment_type]="'photo'"
                  [photo_id]="photo.id"
                  [ngStyle]="{'display': display_comments[ind] ? '' : 'none'}"></app-comments> -->
    <!-- [ngStyle]="{'display': display_comment ? '' : 'none'}"></app-comments> -->
</ng-container>
<!-- </ng-container> -->
<!-- <ng-container *ngIf=" (author$ | async) as author1">
             *ngIf="authenticationService.isOwner(author1.id)">
</ng-container> -->

<button class="scrollbutton"
        *ngIf="isShow"
        (click)="gotoTop()">
    <mat-icon aria-label="hide photo options">
        <span class="material-symbols-outlined">
            vertical_align_top
        </span>
    </mat-icon>
</button>