import { Injectable } from '@angular/core';
import { Contact } from './models/contact';
import { catchError, Observable, of, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { AuthenticationService } from './_services';
import { MessageService } from './services/message.service';
import { UrlService } from './services/url.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient,
    private messageService: MessageService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private urlService: UrlService,
    private logger: NGXLogger) { }

  /** POST: add a new contact to the server */
  addContact(contact: Contact): Observable<Contact> {
    this.logger.debug(`contact to add: ${contact}`);

    return this.http.post<Contact>(this.urlService.getContactsUrl(), contact).pipe(
      tap((xcontact: Contact) => this.logger.debug(`added contact w/ id=${xcontact.id}`)),
      catchError(this.handleError<Contact>('addContact'))
    );
  }



  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
