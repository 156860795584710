import { Subscription } from 'rxjs';
import { AuthenticationService } from '@/_services';
import { Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LanguageDialogComponent } from '@/language-dialog/language-dialog.component';
import { BrowserStorageService } from '@/services/browserstorage.service';
import { LanguageService } from '@/services/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
  standalone: false
})
export class SidenavListComponent implements OnInit, OnDestroy {

  elem: any; isFullScreen: boolean;

  @Output() closeSidenav = new EventEmitter<void>();
  isAuth = false;
  authSubscription: Subscription;
  currentLanguage: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    public translate: TranslateService,
    public languageService: LanguageService,
    private dialog: MatDialog,
    private logger: NGXLogger,
    private browserStorageService: BrowserStorageService,
    public authenticationService: AuthenticationService) { }

  ngOnInit() {
    // removed for ssr
    // this.elem = document.documentElement;
    this.authSubscription = this.authenticationService.authChange.subscribe(authStatus => {
      this.isAuth = authStatus;
    });
    this.currentLanguage = this.getCurrentlanguage();
  }

  onClose() {
    this.closeSidenav.emit();
  }


  logout() {
    this.onClose();
    this.authenticationService.logout();
  }
  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }
  refresh(): void {
    window.location.reload();
  }


  getLanguages(): string[] {
    return this.translate.getLangs().map((lg) => { if (lg == 'zh-hans') { return 'zh' } else return lg });
  }

  getCurrentlanguage(): string {
    return (this.translate.currentLang == 'zh-hans') ? 'zh' : this.translate.currentLang;
  }
  changeLang(lg: string) {
    let language = (lg == 'zh') ? 'zh-hans' : lg;
    this.translate.use(language)
    this.translate.setDefaultLang(language)
    this.browserStorageService.setItem('locale', language)
  }



  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])
  fullscreenmodes(event) {
    // this.chkScreenMode();
  }
  chkScreenMode() {
    if (document.fullscreenElement) {
      // fullscreen
      this.isFullScreen = true;
      this.logger.debug('======================= chkScreenMode fullscreen')
    } else {
      // not in full screen
      this.isFullScreen = false;
      this.logger.debug('======================= chkScreenMode not fullscreen')
    }
  }
  openFullscreen() {
    this.logger.debug('======================= openFullscreen')
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();

      this.logger.debug('======================= requestFullscreen')
    } else if (this.elem.mozRequestFullScreen) {
      this.logger.debug('======================= mozRequestFullScreen')
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      this.logger.debug('======================= webkitRequestFullscreen')
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      this.logger.debug('======================= msRequestFullscreen')
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    this.logger.debug('======================= closeFullscreen')
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.elem.mozCancelFullScreen) {
      /* Firefox */
      this.elem.mozCancelFullScreen();
    } else if (this.elem.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitExitFullscreen();
    } else if (this.elem.msExitFullscreen) {
      /* IE/Edge */
      this.elem.msExitFullscreen();
    }
  }
  callLanguageDialog() {

    this.onClose();
    this.logger.debug('SidenavListComponent callLanguageDialog: ');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '600px';
    dialogConfig.width = '400px';
    dialogConfig.position = {
      left: '10px', top: '10px'
    }
    dialogConfig.data = {
      language: this.getCurrentlanguage()
    };

    const dialogRef = this.dialog.open(LanguageDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log(this.router.url); //  /routename
        var str = this.router.url;
        var res = str.split("/");
        res[1] = this.getCurrentlanguage();
        var finalRoute = res.join("/");
        console.log('final route :', finalRoute); //  /routename
        this.router.navigate([finalRoute]).then(() => { window.location.reload(); });
        console.log("LanguageDialogComponent Dialog output:", data)
      });
  }
}
