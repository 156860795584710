<!-- <mat-sidenav #drawer mode="over" closed role="navigation" class="sdnav"> -->
<mat-nav-list>
    <!--
    
            <a mat-list-item (click)="sidenav.close()" routerLink="{{'/' + languageService.getActivateLanguage()}}/series">
              -->
    <!-- *ngIf="authenticationService.isMemberOfGroup('Editor')" -->
    <a mat-list-item
       *ngIf="authenticationService.isPseudo('picafrou')"
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/subnotif">
        subscrib
    </a>
    <a mat-list-item
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/home">
        <mat-icon>home</mat-icon>
        {{ "home" | translate }}
    </a>
    <a mat-list-item
       *ngIf="isAuth"
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/mysubscriptions">
        <mat-icon>subscriptions</mat-icon>
        <span class="nav-caption">{{ "My Subscriptions" | translate }}</span>
    </a>
    <a mat-list-item
       *ngIf="isAuth"
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/mybookmarks">
        <mat-icon>bookmarks</mat-icon>
        <span class="nav-caption">{{ "My Bookmarks" | translate }}</span>
    </a>
    <a mat-list-item
       *ngIf="isAuth"
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/myliked/1">
        <mat-icon>favorite</mat-icon>
        <span class="nav-caption">{{ "My Liked" | translate }}</span>
    </a>
    <a mat-list-item
       *ngIf="isAuth"
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/myrelations/0">
        <mat-icon>group</mat-icon>
        <span class="nav-caption">{{ "My Relations" | translate }}</span>
    </a>
    <a mat-list-item
       *ngIf="isAuth"
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/mycomments">
        <mat-icon>comment</mat-icon>
        <span class="nav-caption">{{ "My Comments" | translate }}</span>
    </a>

    <!-- <ng-container *ngIf="isAuth">
        <img mat-card-avatar
             class="myavatar episodethumbnail"
             *ngIf="(imageService.ogphotourl(currentUser.photocontainer,currentUser.photoname)|async) as imgurl"
             [src]="imgurl"
             alt="Desc 1"
             routerLink="{{'/' + languageService.getActivateLanguage()}}/mydashboard/1">
    </ng-container> -->
    <!-- <span class="fill-remaining-space"></span> -->
    <a mat-list-item
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/register"
       *ngIf="!isAuth">
        <mat-icon>face</mat-icon>
        <span class="nav-caption">{{ "register" | translate }}</span>
    </a>
    <a mat-list-item
       style="cursor: pointer"
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/login"
       *ngIf="!isAuth">
        <mat-icon>input</mat-icon>
        <span class="nav-caption">{{ "login" | translate }}</span>
    </a>
    <a mat-list-item
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/changepassword"
       *ngIf="isAuth">
        <mat-icon>eject</mat-icon>
        <span class="nav-caption">{{ "change password" | translate }}</span>
    </a>
    <a mat-list-item
       (click)="logout()"
       *ngIf="isAuth">
        <mat-icon>eject</mat-icon>
        <span class="nav-caption">{{ "logout" | translate }}</span>
    </a>
    <a mat-list-item
       (click)="refresh()">
        <mat-icon>refresh</mat-icon>
        <span class="nav-caption">{{ "refresh" | translate }}</span>
    </a>
    <!-- <a mat-list-item
       (click)="openFullscreen()">
        <mat-icon class="screen"
                  *ngIf="!isFullScreen">fullscreen</mat-icon>
    </a>
    <a mat-list-item
       (click)="closeFullscreen()">
        <mat-icon class="screen"
                  *ngIf="isFullScreen">fullscreen_exit</mat-icon>
    </a> -->
    <a mat-list-item
       (click)="onClose()"
       *ngIf="isAuth"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/profile">
        <mat-icon>person</mat-icon>
        <span class="nav-caption">{{ "profile" | translate }}</span>
    </a>
    <a mat-list-item
       (click)="callLanguageDialog()">
        <mat-icon>
            <span class="material-symbols-outlined">
                language
            </span>
        </mat-icon>
        language {{getCurrentlanguage()}}
    </a>
    <a mat-list-item
       (click)="onClose()"
       *ngIf="isAuth"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/settings">
        <mat-icon>settings</mat-icon>
        <span class="nav-caption">{{ "settings" | translate }}</span>
    </a>
    <a mat-list-item
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/contact">
        <mat-icon>settings</mat-icon>
        <span class="nav-caption">{{ "Contact" | translate }}</span>
    </a>
    <a mat-list-item
       (click)="onClose()"
       routerLink="{{'/' + languageService.getActivateLanguage()}}/about">
        <mat-icon>settings</mat-icon>
        <span class="nav-caption">{{ "About" | translate }}</span>
    </a>

    <!-- <a mat-list-item (click)="drawer.close()" routerLink="{{'/' + languageService.getActivateLanguage()}}/sociallogin">Login social</a>
                    <mat-list-item>
                        <button mat-icon-button (click)="drawer.close();logout()" *ngIf="isLoggedIn||true">
                <mat-icon>eject</mat-icon>
                <span class="nav-caption">Logout</span>
              </button>
                    </mat-list-item> -->
    <!-- <a mat-list-item (click)="drawer.close()">close</a> -->
    <a mat-button
       *ngIf="authenticationService.isPseudo('picafrou')"
       [matMenuTriggerFor]="dropdown">
        <mat-icon>menu</mat-icon>
        menu
    </a>
    <mat-menu #dropdown>
        <button mat-icon-button
                routerLink="{{'/' + languageService.getActivateLanguage()}}/search">
            <mat-icon>search</mat-icon>
            search
        </button>
        <button mat-menu-item
                [matMenuTriggerFor]="serie">
            <mat-icon>home</mat-icon>
            series
        </button>
        <button mat-menu-item
                [matMenuTriggerFor]="serie">
            <mat-icon>home</mat-icon>
            episodes
        </button>
        <button mat-menu-item
                [matMenuTriggerFor]="serie">
            <mat-icon>home</mat-icon>
            authors
        </button>
        <button mat-menu-item
                [matMenuTriggerFor]="serie">
            <mat-icon>home</mat-icon>
            comments
        </button>
        <button mat-menu-item
                [matMenuTriggerFor]="language">
            <mat-icon>home</mat-icon>
            language
        </button>
    </mat-menu>
    <mat-menu #language>
        <a mat-list-item
           (click)="callLanguageDialog()">
            <mat-icon class="screen"
                      *ngIf="isFullScreen">
                <span class="material-symbols-outlined">
                    language
                </span>
            </mat-icon>
            language
        </a>
        <label mat-menu-item>

            <select #langSelect
                    (change)="changeLang(langSelect.value)">
                <option *ngFor="let lang of getLanguages()"
                        [value]="lang"
                        [selected]="lang === getCurrentlanguage()">{{ lang }}</option>
            </select>
            <!-- <select #langSelect
                    (change)="changeLang(langSelect.value)">
                <option *ngFor="let lang of translate.getLangs()"
                        [value]="lang"
                        [selected]="lang === translate.currentLang">{{ lang }}</option>
            </select> -->
        </label>
    </mat-menu>
    <mat-menu #serie>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/series">
            <mat-icon>home</mat-icon>
            list
        </button>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/series/new">
            <mat-icon>add</mat-icon>
            create
        </button>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/editserie">
            <mat-icon>edit</mat-icon>
            edit
        </button>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/search">
            <mat-icon>search</mat-icon>
            search
        </button>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/search">
            <mat-icon>search</mat-icon>
            my subscribed series
        </button>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/search">
            <mat-icon>search</mat-icon>
            most subscribed
        </button>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/search">
            <mat-icon>search</mat-icon>
            most recent
        </button>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/search">
            <mat-icon>search</mat-icon>
            most viewed
        </button>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/search">
            <mat-icon>search</mat-icon>
            best rated
        </button>
        <button mat-menu-item
                routerLink="{{'/' + languageService.getActivateLanguage()}}/search">
            <mat-icon>search</mat-icon>
            search
        </button>
    </mat-menu>
</mat-nav-list>