<form class="contact-form-flex"
      [formGroup]="contactForm"
      (ngSubmit)="onSubmit()">
    <mat-card appearance="outlined"
              class="my-card">
        <mat-card-title class="title">
            {{'contact feedback'| translate}}
        </mat-card-title>
        <mat-card-content>

            <mat-form-field class="full-width">
                <mat-label>Email</mat-label>
                <input matInput
                       type="text"
                       id="titleInput"
                       placeholder="Email"
                       [formControl]="contactForm.controls['email']">
                @if (contactForm.controls['email'].value) {
                <button matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="contactForm.controls['email'].setValue('')">
                    <mat-icon>close</mat-icon>
                </button>
                }
                <mat-error *ngIf="contactForm.controls.email.invalid">Email is required</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>Full name</mat-label>
                <input matInput
                       type="text"
                       id="Full Name"
                       placeholder="Full Name"
                       [formControl]="contactForm.controls['fullname']">
                @if (contactForm.controls['fullname'].value) {
                <button matSuffix
                        mat-icon-button
                        aria-label="Clear"
                        (click)="contactForm.controls['fullname'].setValue('')">
                    <mat-icon>close</mat-icon>
                </button>
                }
                <mat-error *ngIf="contactForm.controls.fullname.invalid">Fulli name is
                    required</mat-error>
            </mat-form-field>

            <mat-form-field class="full-width"
                            appearance="outline">
                <mat-label>Message</mat-label>
                <!-- <input matInput
                       type="text"
                       id="descInput"
                       placeholder="Desc"
                       [formControl]="contactForm.controls['desc']"> -->
                <textarea matInput
                          formControlName="message"
                          placeholder="Message"
                          cdkTextareaAutosize=""
                          [cdkAutosizeMinRows]="4"
                          [cdkAutosizeMaxRows]="15"
                          [maxlength]="500">
                                               </textarea>
                <mat-error *ngIf="contactForm.controls.message.invalid">Message is
                    required</mat-error>
            </mat-form-field>


        </mat-card-content>
        <mat-card-actions class="myactions">
            <button mat-raised-button
                    matTooltipPosition="above"
                    matTooltip="Cancel Message"
                    [matTooltipShowDelay]="100"
                    [matTooltipHideDelay]="300"
                    color="accent"
                    type="button"
                    (click)="onCancel()">cancel</button>
            <button mat-raised-button
                    matTooltip="Validate Message"
                    [matTooltipShowDelay]="100"
                    [matTooltipHideDelay]="300"
                    color="primary"
                    [disabled]="contactForm.invalid"
                    type="submit">Submit</button>

        </mat-card-actions>

    </mat-card>
</form>