<mat-card appearance="outlined"
          class="my-card">
    <mat-card-title class="title">
        {{'About.title'| translate}}
    </mat-card-title>
    <mat-card-content [innerHTML]="'About.text' | translate">
    </mat-card-content>
    <mat-card-actions class="myactions">
        <button mat-raised-button
                color="primary"
                type="button"
                id="id_parolebtn"
                (click)="gonext()"
                class="mat-raised-button  btn btn-primary">Ok</button>

    </mat-card-actions>

</mat-card>