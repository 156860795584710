import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { BrowserStorageService } from '@/services/browserstorage.service';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, filter, Observable } from "rxjs";



@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private readonly defaultLanguage = 'fr';
  private readonly englishLanguage = 'en';
  private readonly frenchLanguage = 'fr';
  private readonly germanLanguage = 'de';
  private readonly supportedLanguages = [this.englishLanguage, this.frenchLanguage, this.germanLanguage]

  private currentUrlLangSubject: BehaviorSubject<string>;
  public currentUrlLang$: Observable<string>;


  public getActivateLanguage(): string {
    return this.translate.currentLang
  }
  constructor(private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private browserStorageService: BrowserStorageService,
    private logger: NGXLogger) {
    this.currentUrlLangSubject = new BehaviorSubject<string>(this.getCurrentLanguage());
    this.currentUrlLang$ = this.currentUrlLangSubject.asObservable();
  }


  public init(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {

      const lang = this.route.snapshot.firstChild?.paramMap.get('lang');
      this.logger.debug('language service detected url language : ' + lang)
      if (lang && this.supportedLanguages.includes(lang)) {
        this.translate.use(lang);
      } else {
        this.logger.debug('language service no url language use default : ' + this.defaultLanguage)
        this.translate.use(this.defaultLanguage);
      }
    });
  }

  getCurrentLanguage(): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('getCurrentLanguage language ' + lang)
    if (this.browserStorageService.getItem('locale')) {
      this.translate.setDefaultLang(this.browserStorageService.getItem('locale'));
      this.translate.use(this.browserStorageService.getItem('locale'));
      this.logger.debug('getCurrentLanguage locale found ' + this.browserStorageService.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      this.browserStorageService.setItem('locale', 'fr');
      this.logger.debug('getCurrentLanguage   locale not found ' + this.browserStorageService.getItem('locale'));
    }
    return lang;
  }

  getAvailableLanguages(): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (this.browserStorageService.getItem('locale')) {
      this.translate.setDefaultLang(this.browserStorageService.getItem('locale'));
      this.translate.use(this.browserStorageService.getItem('locale'));
      this.logger.debug('getAvailableLanguages locale found ' + this.browserStorageService.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      this.browserStorageService.setItem('locale', 'fr');
      this.logger.debug('getAvailableLanguages locale not found ' + this.browserStorageService.getItem('locale'));
    }
    return lang;
  }

  public setLanguage(): string {
    let lang = 'fr';
    lang = this.translate.currentLang;
    this.logger.debug('current language in serie ' + lang)
    if (this.browserStorageService.getItem('locale')) {
      this.translate.setDefaultLang(this.browserStorageService.getItem('locale'));
      this.translate.use(this.browserStorageService.getItem('locale'));
      this.logger.debug('setLanguage locale found ' + this.browserStorageService.getItem('locale'));
    } else {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      this.browserStorageService.setItem('locale', 'fr');
      this.logger.debug('setLanguage component  locale not found ' + this.browserStorageService.getItem('locale'));
    }
    return lang;
  }

  public changeLanguage(language: string): string {
    if (this.supportedLanguages.includes(language)) {
      this.translate.use(language);
      this.browserStorageService.setItem('locale', language);
      this.currentUrlLangSubject.next(language);
      this.logger.debug('changeLanguage set to ' + language)
    }
    return language;
  }
}