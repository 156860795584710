import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, AlertService } from '@/_services';
import { first } from 'rxjs/operators';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { LanguageService } from '@/services/language.service';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: false
})

export class ForgotpasswordComponent implements OnInit {
  forgotpasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  protected error: string;
  sent: boolean;

  get email() { return this.forgotpasswordForm.get('email'); }

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private languageService: LanguageService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private logger: NGXLogger
  ) {
    this.sent = false;
    console.log('ngOnInit ForgotPasswordComponent constructor');

  }

  ngOnInit(): void {
    console.log('ngOnInit ForgotPasswordComponent');

    this.forgotpasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.sent = false;
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    console.log('ngOnInit ForgotPasswordComponent done');

  }

  send(): void {
    this.error = null;
  }

  // convenience getter for easy access to form fields
  get f() { return this.forgotpasswordForm.controls; }

  onSubmit(): void {
    console.log('submit ForgotPasswordComponent');

    // stop here if form is invalid
    if (this.forgotpasswordForm.invalid) {
      console.log('submit ForgotPasswordComponent error');

      return;
    }

    this.loading = true;
    this.authenticationService.resetPwd(this.f.email.value.toLowerCase())
      .pipe(first())
      .subscribe(
        data => {
          console.log('ForgotPasswordComponent onSubmit loginForm called authenticationService.login');
          console.log('ForgotPasswordComponent onSubmit loginForm called authenticationService.data' + data);
          console.log('ForgotPasswordComponent onSubmit submit loginForm nexturl : ' + this.returnUrl);

          this.loading = false;
          this.openDialog();
          this.logger.debug('return url : ' + this.returnUrl)
          // this.router.navigate([this.returnUrl]);
          this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/home']);
        },
        error => {
          console.log('error loginForm called authenticationService.login');
          this.alertService.error(error);
          this.loading = false;
        });
    return;

  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
