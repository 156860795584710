import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { catchError, first } from 'rxjs/operators';
import { AlertService, AuthenticationService } from '@/_services';
import { UserService } from '@/services/user.service';
import { User } from '@/models/user';
import { MustMatch } from '../_helpers/must-match.validator';
import { NGXLogger } from 'ngx-logger';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RegistrationDoneDialogComponent } from './registration-done-dialog/registration-done-dialog.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { logger } from '@azure/storage-blob';
import { sleep } from '@/shared/util';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { CguDialogComponent } from '@/cgu-dialog/cgu-dialog.component';
import { Meta } from '@angular/platform-browser';
import { LanguageService } from '@/services/language.service';
// import { constructor } from 'jasmine';
// import { get } from 'jquery';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control?.invalid && control?.parent?.dirty);
    const invalidParent = !!(control?.parent?.invalid && control?.parent?.dirty);

    return invalidCtrl || invalidParent;
  }
}


export function createPasswordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const hasUpperCase = /[A-Z]+/.test(value);
    const hasLowerCase = /[a-z]+/.test(value);
    const hasNumeric = /[0-9]+/.test(value);
    const passwordValid = hasUpperCase && hasLowerCase && hasNumeric;
    return !passwordValid ? { passwordStrength: true } : null;
  }
}

@Component({
  selector: 'app-registration-component',
  templateUrl: './registration-component.component.html',
  styleUrls: ['./registration-component.component.scss'],
  standalone: false
})
export class RegistrationComponentComponent implements OnInit {

  registerForm: FormGroup;
  isLoading = false;
  submitted = false;
  hide = true;
  matcher: ErrorStateMatcher;
  error: string = null;
  cguaccepted = false;

  get password() { return this.registerForm.get('password'); }
  get confirmPassword() { return this.registerForm.get('confirmPassword'); }
  get email() { return this.registerForm.get('email'); }
  get pseudo() { return this.registerForm.get('pseudo'); }

  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  getPasswordErrorMessage() {
    if (this.password.hasError('required')) {
      return 'You must enter a password value';
    }
    if (this.password.hasError('minlength')) {
      return 'Password should be at least 8 characters';
    }
    if (this.password.hasError('pattern')) {
      return 'Must contain 1 number, 1 capital, 1 special';

    }
    return 'password error';
  }
  constructor(
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private meta: Meta,
    private logger: NGXLogger
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/']);
    }
  }

  ngOnInit() {
    this.meta.removeTag('name="robots"');
    this.meta.addTag({ name: 'robots', content: 'noindex, nofollow' });


    const StrongPasswordRegx: RegExp =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    this.logger.debug('RegistrationComponentComponent ngOnInit');
    this.registerForm = this.formBuilder.group({
      // firstName: ['', Validators.required],
      // lastName: ['', Validators.required],
      email: ['', [Validators.required, , Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8),
      // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{8,}')
      // Validators.pattern('^[a-zA-Z0-9!@#$ %^&*()]+$')
      // Validators.pattern('^(?=.*[0-9])(?=.*[a-z][A-Z])(?=.*[//,.?;<>:!@#$%^&*(-=_+)|{}\[\]])([a-zA-Z0-9//,.?;<>\':\"!@#$%^&*(-=_+)|{}\[\]]{8,20})$')
      Validators.pattern(StrongPasswordRegx)
      ]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8)]],
      pseudo: ['', [Validators.required]],
      acceptTerms: [false, Validators.requiredTrue]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.logger.debug('RegistrationComponentComponent ngOnInit end');
    this.matcher = new MyErrorStateMatcher();
    this.cguaccepted = false;

  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  get passwordMatchError() {
    return (
      this.registerForm.getError('mustMatch') &&
      this.registerForm.get('confirmPassword')?.touched
    );
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      console.log('RegistrationComponentComponent invalid form');

      return;
    }
    console.log('RegistrationComponentComponent valid form');
    console.log('RegistrationComponentComponent valid data ' + this.registerForm.value);
    const user: User = new User();
    user.email = this.registerForm.value['email'].toLowerCase();
    user.password = this.registerForm.value['password'];
    user.pseudo = this.registerForm.value['pseudo'];

    this.isLoading = true;
    await sleep(1000);
    this.userService.register(user)
      .pipe(catchError(this.handleError), first())
      .subscribe({
        next: data => {
          console.log('RegistrationComponentComponent post done' + JSON.stringify(data));
          this.alertService.success('Registration successful', true);
          // this.userService.getByMail(user.email)
          //   .pipe(catchError(this.handleError), first())
          //   .subscribe({
          //     next: data => {
          //       console.log('RegistrationComponentComponent registered user' + JSON.stringify(data));
          //       this.userService.setPseudoById(data.id, user.pseudo).subscribe()
          //     },
          //     error: (error) => {
          //       console.log('RegistrationComponentComponent get by email' + error);
          //       this.alertService.error(error);
          //       this.error = error;
          //       this.isLoading = false;
          //     }
          //   })
          this.isLoading = false;
          this.openDialog();
          this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/login']);
        },

        error: (error) => {
          console.log('RegistrationComponentComponent post error' + error);
          this.alertService.error(error);
          this.error = error;
          this.isLoading = false;
        }
      });
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(RegistrationDoneDialogComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openCguDialog(): void {
    this.logger.debug('cguDialog ');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: 1,
    };
    const dialogRef = this.dialog.open(CguDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closedi result ' + result);
      if (result == 'accepted') this.cguaccepted = true
    });
  }
  private handleError(errorRes: HttpErrorResponse) {
    console.log(' registration failed');
    console.log(JSON.stringify(errorRes));

    let errorMessage = 'An unknown error occurred!';
    if (!errorRes.error) {
      console.log(' login unknown error case');
      // throwError(() => {
      //     const error: any = new Error(errorMessage);
      //     error.timestamp = Date.now();
      //     return error;
      // });
      // return throwError(errorMessage);
    }
    else if (errorRes.error.email) {
      console.log('is email is already used');
      errorMessage = 'This email is already used';
    }
    else if (errorRes.error.pseudo) {
      console.log('A user is already registered with this pseudo');
      errorMessage = 'A user is already registered with this pseudo';
    }
    // return new Error(errorMessage);
    return throwError(() =>
      new Error(errorMessage)
      // error.timestamp = Date.now();
      // return error;
    );
  }
}

