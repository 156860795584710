import { AuthenticationService, AlertService } from '@/_services';
import { ContactService } from '@/contact.service';
import { Contact } from '@/models/contact';
import { LanguageService } from '@/services/language.service';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
  standalone: false
})
export class ContactComponent {

  contactForm: FormGroup;
  error: string = null;

  constructor(
    private formBuilder: FormBuilder,
    public languageService: LanguageService,
    public contactService: ContactService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private meta: Meta,
    private alertService: AlertService,
    private logger: NGXLogger
  ) {
  }

  ngOnInit() {

    this.contactForm = this.formBuilder.group({
      email: ['', {
        validators: [Validators.required, Validators.email]
      }],
      fullname: ['', [Validators.required, Validators.minLength(8)]],
      message: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  async onSubmit() {

    console.log('submit contactForm');

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      console.log('submit contactForm error');

      return;
    }

    let contact: Contact;
    this.contactForm;
    this.contactService.addContact(this.contactForm.value).subscribe({
      next: (data) => {
        this.router.navigate(['/']);
      },
      error: (error) => {
        console.log('error contactForm called authenticationService.login');
        // this.alertService.error(error);
        this.error = error;
      }
    });
  }

  goBack(): void {
    this.location.back();
  }
  onCancel() {
    this.goBack();
    // this.router.navigate(['../'], { relativeTo: this.route });
  }
}
