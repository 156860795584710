import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, AlertService } from '@/_services';
import { first } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { LanguageService } from '@/services/language.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  standalone: false
})


export class EmailVerificationComponent implements OnInit {

  error: string;
  // error messages received from the login attempt
  public errors: any = [];
  verifying: boolean;
  verified: boolean;
  verificationFailed: boolean;
  protected uid: string;
  protected emailVerificationToken: string;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public languageService: LanguageService,
    private logger: NGXLogger,
    private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.verifying = false;
    this.verified = false;
    this.verificationFailed = false;

    this.uid = this.route.snapshot.paramMap.get('uid');
    this.emailVerificationToken = this.route.snapshot.paramMap.get('emailVerificationToken');
    this.logger.debug('verification token ' + this.emailVerificationToken)
    if (this.emailVerificationToken) {
      this.verify();
    }
  }

  verify(): void {
    this.verifying = true;
    this.authenticationService.verifyEmail(this.emailVerificationToken)
      .pipe(first())
      .subscribe(
        {
          next: (data) => {
            console.log('EmailVerificationComponent onSubmit loginForm called authenticationService.data' + data);
            this.verified = true;
            this.verifying = false;
            //          this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/series']);
          },
          error: (error) => {
            console.log('email verification failed, Need to resend confirmation email');
            this.alertService.error(error);
            this.verificationFailed = true;
            this.verifying = false;
            //         this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/resendemailverification']);
          }
        });
    return;
  }
}
