<mat-card appearance="outlined"
          class="my-card">
    <mat-card-header class="thin">
        <div class="toprow">
            <button mat-icon-button
                    class="back"
                    (click)="goBack()">
                <mat-icon><span class="material-symbols-outlined">
                        arrow_back_ios
                    </span></mat-icon>
            </button>
            <!-- src="{{ imageService.gphotourl(author.photocontainer,author.photoname) }}" -->
            <img *ngIf="author"
                 class="myavatar"
                 [src]="imageService.ogphotourl(author.photocontainer,author.photoname)|async"
                 alt="Desc 1"
                 (click)="openfullscreenimageDialog()">
            <mat-card-title *ngIf="author">&#64;{{author.pseudo}}</mat-card-title>
        </div>
    </mat-card-header>
    <!-- <mat-card-content>
        <mat-card-subtitle class="multilinetext">{{author.bio}}</mat-card-subtitle>
    </mat-card-content> -->
    <!-- <mat-card-actions align="right">
    </mat-card-actions> -->
</mat-card>

<mat-button-toggle-group class="buttons"
                         name="fontStyle"
                         aria-label="Font Style">
    <mat-button-toggle value="grid"
                       (change)="onValChange($event.value)">
        <mat-icon class="example-tab-icon"
                  aria-label="toggle fullscreen">grid_on</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="list"
                       (change)="onValChange($event.value)">
        <mat-icon class="example-tab-icon"
                  aria-label="toggle fullscreen">list</mat-icon>
    </mat-button-toggle>

    <!-- mat-mini-fab -->
</mat-button-toggle-group>
<!-- [swipeLimitWidth]="80"
               [connectEdges]="true" -->
<mat-tab-group #tabgroup
               (touchstart)="swipe($event, 'start')"
               (touchend)="swipe($event, 'end')"
               matTabGroupGesture
               class="tabs"
               [(selectedIndex)]="selectedTabNum"
               (selectedIndexChange)="changeSelectedTabNum($event)">


    <mat-tab label="{{'My Picashoots'|translate}}"
             *ngIf="authenticationService.isLoggedIn()">
        <div class="addbutton">
            <div class="centeradd">
                <button mat-raised-button
                        color="primary"
                        class="picashoot"
                        *ngIf="authenticationService.isLoggedIn()"
                        [routerLink]="['/editphoto/0',{episodeMode: 'photoshoot'}]">
                    <mat-icon aria-label="add serie"><span class="material-symbols-outlined">
                            photo_camera_front
                        </span></mat-icon>
                    <mat-icon aria-label="add serie">add</mat-icon>
                    <div class="picashoot">Picashoot</div>
                </button>
            </div>
        </div>
        <div *ngIf="(myPhotoshoots$ | async) as myPhotoshoots"
             class="flexd">
            <app-photos [title]="'My Picashoots'"
                        [photos]="myPhotoshoots"
                        [chooseDisplayMode]="false"
                        [displayTitle]="false"
                        [displayMode]="display">
            </app-photos>
        </div>
    </mat-tab>
    <mat-tab label="{{'My Series'|translate}}"
             *ngIf="authenticationService.isLoggedIn()">
        <div *ngIf="(mySeries$ | async) as mySeries"
             class="flexd">
            <app-series [title]="'My draft series'"
                        [series]="mySeries"
                        [chooseDisplayMode]="false"
                        [displayTitle]="false"
                        [displayMode]="display">
            </app-series>
        </div>
    </mat-tab>


    <mat-tab label="{{'My Episodes'|translate}}"
             *ngIf="authenticationService.isLoggedIn()">
        <div *ngIf="(myEpisodes$ | async) as myEpisodes"
             class="flexd">
            <app-episodes [title]="'My draft episodes'"
                          [episodes]="myEpisodes"
                          [chooseDisplayMode]="false"
                          [displayTitle]="false"
                          [displayMode]="display">
            </app-episodes>
        </div>
    </mat-tab>


</mat-tab-group>