import { LanguageService } from '@/services/language.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'app-about',
    standalone: false,
    templateUrl: './about.component.html',
    styleUrl: './about.component.scss'
})
export class AboutComponent {


    constructor(
        private router: Router,
        private languageService: LanguageService,
        private logger: NGXLogger
    ) {
        this.logger.debug('DeletePhotoDialogComponent constructor ');
    }

    ngOnInit(): void {
    }

    gonext() {
        this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/home/']);
    }

    close() {
    }
}
