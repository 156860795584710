import { Location } from '@angular/common';
import { sleep } from '@/shared/util';
import { MustMatch } from '@/_helpers/must-match.validator';
import { AuthenticationService, AlertService } from '@/_services';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { PasswordChangedDialogComponent } from './password-changed-dialog/password-changed-dialog.component';
import { LanguageService } from '@/services/language.service';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-changepassword',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  standalone: false
})
export class ChangePasswordComponent implements OnInit {

  protected error: string;
  changepasswordForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  hide = true;
  get password1() { return this.changepasswordForm.get('password1'); }
  get password2() { return this.changepasswordForm.get('password2'); }

  getPasswordErrorMessage() {
    if (this.password1.hasError('required')) {
      return 'You must enter a ipassword value';
    }

    return this.password1.hasError('minlength') ? 'password should be at least 6 characters' : ' ';
  }
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authenticationService: AuthenticationService,
    public languageService: LanguageService,
    private alertService: AlertService,
    public dialog: MatDialog
  ) {
    // redirect to home if already logged in
    if (!this.authenticationService.currentUserValue) {
      console.log('constructor component not logedi cannot change password');
      console.log('constructor navigating to /');
      this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/']);
    }
  }

  ngOnInit() {
    this.changepasswordForm = this.formBuilder.group({
      currentpassword: ['', Validators.required],
      password1: ['', [Validators.required, Validators.minLength(6)]],
      password2: ['', Validators.required]
    }, {
      validator: MustMatch('password1', 'password2')
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.changepasswordForm.controls; }

  async onSubmit() {
    this.submitted = true;
    console.log('submit loginForm');

    // stop here if form is invalid
    if (this.changepasswordForm.invalid) {
      console.log('submit loginForm error');

      return;
    }

    this.loading = true;
    await sleep(1000);

    this.authenticationService.passwordChange(this.f.password1.value, this.f.password1.value, this.f.currentpassword.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log('LoginComponentComponent onSubmit loginForm called authenticationService.data' + data);
          this.loading = false;
          this.openDialog();
          this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/dashboard']);
        },
        error => {
          console.log('error loginForm called authenticationService.login');
          this.alertService.error(error);
          this.loading = false;
        });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(PasswordChangedDialogComponent, {
      width: '250px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  goBack(): void {
    this.location.back();
  }

  onCancel() {
    this.goBack();
    // this.router.navigate(['../'], { relativeTo: this.route });
  }
}
