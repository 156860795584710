import { Episode } from '@/models/episode';
import { EpisodeService } from '@/services/episode.service';
import { ImageService } from '@/services/image.service';
import { AuthenticationService } from '@/_services';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Serie } from '../models/serie';
import { SerieService } from '../services/serie.service';
import { PhotoService } from '@/services/photo.service';
import { Photo } from '@/models/photo';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { UserService } from '@/services/user.service';
import { AuthorService } from '@/services/author.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Author } from '@/models/author';
import { FullscreenImageDialogComponent } from '@/fullscreen-image-dialog/fullscreen-image-dialog.component';
import { Location } from '@angular/common';
import { TextMessageDialogComponent } from '@/text-message-dialog/text-message-dialog.component';
import { LanguageService } from '@/services/language.service';

@Component({
  selector: 'app-mydashboard',
  templateUrl: './mydashboard.component.html',
  styleUrls: ['./mydashboard.component.scss'],
  standalone: false
})
export class MydashboardComponent implements OnInit {

  @ViewChild('tabgroup', { static: true }) tabgroup: MatTabGroup;
  @Input() selectedTabNum: number = 2;
  @Input() author: Author;
  public display = 'grid'; // possible value list or grid
  series$: Observable<Serie[]>;
  mySeries$: Observable<Serie[]>;
  myDraftSeries$: Observable<Serie[]>;
  episodes$: Observable<Episode[]>;
  myEpisodes$: Observable<Episode[]>;
  myDraftEpisodes$: Observable<Episode[]>;
  photoshoots$: Observable<Photo[]>;
  myPhotoshoots$: Observable<Photo[]>;

  authId: number;
  isfollowedbyme: boolean;
  nbfollowers: number;
  iscommentedbyme: boolean;
  nbcomments: number;
  display_comment: boolean = true;

  constructor(private serieService: SerieService,
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private userService: UserService,
    private authorService: AuthorService,
    public authenticationService: AuthenticationService,
    public imageService: ImageService,
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    private episodeService: EpisodeService,
    private photoService: PhotoService,
    private logger: NGXLogger) { }

  ngOnInit() {
    // why here ?????
    // this.authenticationService.refreshToken().subscribe();
    const id = +this.route.snapshot.paramMap.get('tabid');
    this.selectedTabNum = id;
    this.authId = +this.authenticationService.currentUserValue.id
    this.series$ = this.serieService.getSeries();
    if (this.authenticationService.isLoggedIn()) {
      this.mySeries$ = this.serieService.getMySeries();
      this.myEpisodes$ = this.episodeService.getMyEpisodes();
      this.myPhotoshoots$ = this.photoService.getMyPhotoshoots();
    }
    this.logger.debug('dashboard series$ and episodes$ set');
    this.userService.nbFollowers(this.authId).subscribe(data => this.nbfollowers = data);
    this.userService.nbcomments(this.authId).subscribe(data => this.nbcomments = data);
    this.getAuthor(this.authId).subscribe(author => {
      this.author = author;
    });
  }


  onValChange(value) {
    console.log('onValChange');
    console.log(value);
    this.display = value;
  }

  changeSelectedTabNum(tabnum) {
    this.selectedTabNum = tabnum;
    this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/mydashboard/' + tabnum]);
  }
  // public selected: number;
  private swipecoord?: [number, number];
  private swipetime?: number;
  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipecoord = coord;
      this.swipetime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipecoord[0], coord[1] - this.swipecoord[1]];
      const duration = time - this.swipetime;

      if (duration < 1000 //
        && Math.abs(direction[0]) > 30 // long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        switch (swipe) {
          case 'previous':
            if (this.selectedTabNum > 0) { this.selectedTabNum--; }
            break;
          case 'next':
            if (this.selectedTabNum < this.tabgroup._tabs.length - 1) { this.selectedTabNum++; }
            break;
        }
      }
    }
  }


  getAuthor(id: number): Observable<Author> {
    // console.log('AuthorDetailComponent getSerie id: ' + id);
    return this.authorService.getAuthor(id);
  }

  follow(author: Author): void {
    this.authorService.followAuthor(author).subscribe();
  }
  openfullscreenimageDialog() {
    this.imageService.ogphotourl(this.author.photocontainer, this.author.photoname).subscribe(
      x => {
        const dialog_ref = this.dialog.open(FullscreenImageDialogComponent, {
          panelClass: ['full-screen'],
          data: { imgUrl: x },
          // data: { imgUrl: this.imageService.ogphotourl(this.author.photocontainer, this.author.photoname) },
        });
        dialog_ref.afterClosed().subscribe(result => { });
      });
  }


  textMessageDialog() {
    this.logger.debug('EditPhotoComponent deleteTextDialog: ');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      textMessage: 'You need either to:<p><p> <strong> Select a serie </strong>  to create a new episode for it<p> <strong> Create a new Serie </strong>  and then create the episode in it'

    };

    const dialogRef = this.dialog.open(TextMessageDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        console.log("Delete Dialog output:", data)
      });
  }

  goBack(): void {
    this.location.back();
  }
}
