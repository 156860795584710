import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { Episode } from '../models/episode';
import { EpisodeService } from '../services/episode.service';
import { RecommendationService } from '../services/recommendation.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, SubjectLike, of, map } from 'rxjs';
import { NGXLogger } from 'ngx-logger';



import { Page } from '../pagination';
import { AuthenticationService } from '@/_services';
import { ImageService } from '@/services/image.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OriginConnectionPosition } from '@angular/cdk/overlay';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteEpisodeDialogComponent } from '@/delete-episode-dialog/delete-episode-dialog.component';
import { Router } from '@angular/router';
import { Serie } from '@/models/serie';
import { SerieService } from '@/services/serie.service';
import { SharingDialogComponent } from '@/serie-detail/sharing-dialog/sharing-dialog.component';
import { LanguageService } from '@/services/language.service';

@Component({
  selector: 'app-episodes',
  templateUrl: './episodes.component.html',
  styleUrls: ['./episodes.component.scss'],
  standalone: false
})

// export class EpisodesComponent implements OnInit, OnChanges {
export class EpisodesComponent implements OnInit {
  @Input() serie_id: number;
  @Input() order_by_numero: boolean = false;
  @Input() author_id: number;
  @Input() user_id: number;
  @Input() title = 'Episodes';
  @Input() displayMode = 'list';
  @Input() displayTitle = false;
  @Input() displayAdd = false;
  @Input() chooseDisplayMode = false;
  @Input() virtualDisplay: boolean = false;
  // displayModeValue = 'list';
  // @Input() set displayMode(displayM: string) {
  //   console.log(displayM);
  //   if (displayM) {
  //     this.displayModeValue = displayM; // possible value list or grid}
  //   } else {
  //     this.displayModeValue = 'list'; // possible value list or grid
  //   }
  // }
  @Input() episodes: Episode[];
  filterForm: FormGroup;
  // page: Observable<Page<Episode>>;

  episodes$: Observable<Episode[]>;
  serie$: Observable<Serie>;
  // episodes$: Observable<Page<Episode>>;
  cols = 3;
  rowHeight = '500px';
  handsetPortrait = false;

  nblikes: number = 0;
  nbcomments: number = 0;
  nbbookmarks: number = 0;
  nbshares: number = 0;

  constructor(private episodeService: EpisodeService,
    private authenticationService: AuthenticationService,
    private recommendationService: RecommendationService,
    private imageService: ImageService,
    private languageService: LanguageService,
    private serieService: SerieService,
    private responsive: BreakpointObserver,
    private router: Router,
    public dialog: MatDialog,
    private logger: NGXLogger) { }

  ngOnInit() {
    // getNbLikes(serieId: number): Observable<number> {

    //   return this.serieService.nblikes(serieId);
    // }
    // this.serieService.nbcomments(id).subscribe(data => this.nbcomments = data);
    // this.serieService.isratedbymeSerie(id).subscribe(data => { this.isratedbyme = data; });
    // this.serieService.nbratings(id).subscribe(data => { this.nbratings = data; });
    // this.serieService.myrating(id).subscribe(data => { this.myrating = data; });
    // this.serieService.nbsubscriptions(id).subscribe(data => this.nbsubscriptions = data);  //    this.getEpisodes();
    if (this.serie_id) {
      this.serie$ = this.serieService.getSerie(this.serie_id)
      this.episodes$ = this.getSerieEpisodes(this.serie_id);
    } else if (this.author_id) {
      console.log("episodes nginit author_id :" + this.author_id)
      this.episodes$ = this.getAuthorEpisodes(this.author_id);
    } else if (this.user_id) {
      this.episodes$ = this.getrecommendedEpisodes(this.user_id);
    } else if (this.episodes) {
      // this.logger.debug('episodes :' + this.episodes);
      this.episodes$ = of(this.episodes);
    } else {
      this.logger.debug('no episodes set:');
      this.episodes$ = this.getEpisodes();
    }
    this.responsive.observe([Breakpoints.TabletPortrait,
    Breakpoints.TabletLandscape,
    Breakpoints.HandsetPortrait,
    Breakpoints.HandsetLandscape
    ])
      .subscribe(result => {
        // console.log(result);
        this.cols = 3;
        this.rowHeight = '325px';
        this.handsetPortrait = false;


        const breakpoints = result.breakpoints;
        if (breakpoints[Breakpoints.TabletPortrait]) {
          this.cols = 1;
        } else if (breakpoints[Breakpoints.HandsetPortrait]) {
          this.cols = 1;
          this.handsetPortrait = true;
          console.log('this.handsetPortrait set');
        } else if (breakpoints[Breakpoints.HandsetLandscape]) {
          this.cols = 1;
        } else if (breakpoints[Breakpoints.TabletLandscape]) {
          this.cols = 2;
        }

      });
  }


  getAuthorEpisodes(author_id: number): Observable<Episode[]> {
    return this.episodeService.getAuthorEpisodes(author_id);
  }

  getSerieEpisodes(serie_id: number): Observable<Episode[]> {
    return this.episodeService.getSerieEpisodes(serie_id, 1);
  }

  getrecommendedEpisodes(user_id: number): Observable<Episode[]> {
    return this.recommendationService.getRecommendedEpisodes(user_id).pipe(
      map(events => {
        return events.results;
      })
    );
  }
  getEpisodes(): Observable<Episode[]> {
    return this.episodeService.getEpisodes();
  }

  add(name: string): void {
    // name = name.trim();
    // if (!name) { return; }
    // this.episodeService.addEpisode({ name } as Episode)
    //   .subscribe(hero => {
    //     this.episodes.push(hero);
    //   });
  }

  delete(episode: Episode): void {
    this.episodes = this.episodes.filter(h => h !== episode);
    this.episodeService.deleteEpisode(episode).subscribe();
  }

  like(episode: Episode): void {
    this.episodeService.likeEpisode(episode).subscribe();
  }

  bookmark(episode: Episode): void {
    this.episodeService.bookmarkEpisode(episode).subscribe();
  }

  toggledisplayMode(): void {
    this.displayMode = (this.displayMode === 'list') ? 'grid' : 'list';
  }
  onValChange(value) {
    console.log(value);
    this.displayMode = value;
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log('onchanges');
  //   console.log(changes);
  //   //   const uid = changes?.uid?.currentValue ?? this.uid;
  //   //   const skipAnimation = changes.likes?.isFirstChange() || changes?.uid?.isFirstChange();
  //   //   if (uid && (likes || []).includes(uid)) {
  //   //     if (!skipAnimation) {
  //   //       this.likeState = 'liked';
  //   //     }
  //   //     this.liked = true;
  //   //   } else {
  //   //     this.likeState = 'unliked';
  //   //     this.liked = false;
  //   //   }
  // }
  // ngOnChanges(changes: SimpleChanges) {
  // this.logger.debug('onChanges changes ' + JSON.stringify(changes));
  //this.episodes$ = of(this.episodes); // ?????
  // }


  deleteEpisodeDialog(episode_id: number): void {
    this.logger.debug('deleteEpisodeDialog ' + episode_id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id: episode_id,
    };
    const dialogRef = this.dialog.open(DeleteEpisodeDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.router.navigate(['/' + this.languageService.getActivateLanguage() + '/series/' + this.serie_id]);
    });
  }

  openShareDialog(): void {
    const dialogRef = this.dialog.open(SharingDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
