import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BookmarksComponent } from './bookmarks/bookmarks.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { LikesComponent } from './likes/likes.component';
import { SeriesComponent } from './series/series.component';
import { SerieFormComponent } from './serie-form/serie-form.component';
import { SerieDetailComponent } from './serie-detail/serie-detail.component';
import { EpisodesComponent } from './episodes/episodes.component';
import { EpisodeFormComponent } from './episode-form/episode-form.component';
import { EpisodeDetailComponent } from './episode-detail/episode-detail.component';
import { UploaddialogComponent } from './uploaddialog/uploaddialog.component';
import { PhotosComponent } from './photos/photos.component';
import { PhotoDetailComponent } from './photo-detail/photo-detail.component';
import { EditPhotoComponent } from './edit-photo/edit-photo.component';
import { TextesComponent } from './textes/textes.component';
import { AuthorsComponent } from './authors/authors.component';
import { EpisodeEditComponent } from './episode-edit/episode-edit.component';
// import { BlobModule } from 'angular-azure-blob-service';
import { RegistrationComponentComponent } from './registration-component/registration-component.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { LogoutComponent } from './logout/logout.component';
import { ForgotpasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { ResendEmailVerificationComponent } from './resend-email-verification/resend-email-verification.component';

import { AuthGuard } from './_guards/auth.guard';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings/settings.component';
import { AuthorDetailComponent } from './author-detail/author-detail.component';
// import { ChatcliComponent } from './chatcli/chatcli.component';
import { SearchpageComponent } from './searchpage/searchpage.component';
import { CommentsComponent } from './comments/comments.component';
import { MycommentsComponent } from './mycomments/mycomments.component';
import { SocialloginComponent } from './sociallogin/sociallogin.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SerieSearchComponent } from './serie-search/serie-search.component';
import { FollowedComponent } from './followed/followed.component';
import { EpisodeCarrouselComponent } from './episode-carrousel/episode-carrousel.component';
import { EpisodeLinearcarrouselComponent } from './episode-linearcarrousel/episode-linearcarrousel.component';
import { SharesComponent } from './shares/shares.component';
import { MydashboardComponent } from './mydashboard/mydashboard.component';
import { CguDialogComponent } from './cgu-dialog/cgu-dialog.component';
import { AdpanelComponent } from './adpanel/adpanel.component';
import { Page404Component } from './page404/page404.component';
import { SubscribeToNotificationComponent } from './subscribe-to-notification/subscribe-to-notification.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  /*   { path: '', component: SeriesComponent, canActivate: [AuthGuard] }, */
  // { path: '', redirectTo: '/dashboard/1', pathMatch: 'full' },
  // {
  //   path: '',
  //   redirectTo: 'en/home',
  //   pathMatch: 'full',
  // },
  { path: '', component: DashboardComponent },
  {
    path: ":lang",
    children: [
      { path: 'about', component: AboutComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'login', component: LoginComponentComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'register', component: RegistrationComponentComponent },
      { path: 'cgu', component: CguDialogComponent },
      { path: 'forgotpassword', component: ForgotpasswordComponent },
      { path: 'resetpassword/:uid/:resetPasswordToken', component: ResetPasswordComponent },
      { path: 'rstpwd/:uid/:resetPasswordToken', component: ResetPasswordComponent },
      // { path: 'en/resetpassword/:uid/:resetPasswordToken', component: ResetPasswordComponent },
      { path: 'changepassword', component: ChangePasswordComponent },
      { path: 'emailverification/:emailVerificationToken', component: EmailVerificationComponent },
      { path: 'resendemailverification', component: ResendEmailVerificationComponent },
      { path: 'sociallogin', component: SocialloginComponent },

      { path: 'profile', component: ProfileComponent }, // , canActivate: [AuthGuard] },
      { path: 'settings', component: SettingsComponent }, // , canActivate: [AuthGuard] },

      // { path: 'home', component: DashboardComponent },
      // { path: 'home', redirectTo: '/dashboard/1', pathMatch: 'full' },
      { path: 'home', component: DashboardComponent },
      { path: 'dashboard/:tabid', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'mydashboard/:tabid', component: MydashboardComponent },
      { path: 'mysubscriptions', component: SubscriptionsComponent },
      { path: 'mybookmarks', component: BookmarksComponent },

      { path: 'myliked/:tabid', component: LikesComponent },
      { path: 'mylikedseries', component: LikesComponent },
      { path: 'mylikedepisodes', component: LikesComponent },
      { path: 'mylikedphotos', component: LikesComponent },

      { path: 'myshares', component: SharesComponent },
      { path: 'mysharedseries', component: SharesComponent },
      { path: 'mysharedepisodes', component: SharesComponent },
      { path: 'mysharedphotos', component: SharesComponent },

      { path: 'myrelations/:tabid', component: FollowedComponent },
      { path: 'myfriends', component: FollowedComponent },
      { path: 'myfollowed', component: FollowedComponent },
      { path: 'myfollowers', component: FollowedComponent },
      { path: 'mycomments', component: MycommentsComponent },
      { path: 'search', component: SerieSearchComponent },

      { path: 'series', component: SeriesComponent },
      { path: 'series/new', component: SerieFormComponent },
      { path: 'series/:id', component: SerieDetailComponent },
      { path: 'series/:id/:name', component: SerieDetailComponent },
      { path: 'editserie/:serie_id', component: SerieFormComponent },

      { path: 'episodes', component: EpisodesComponent },
      //  { path: 'episodes', component: EpisodesComponent, canActivate: [AuthGuard] },
      { path: 'episodes/new/:serie_id', component: EpisodeFormComponent },
      // { path: 'episodes/:id', component: EpisodeLinearcarrouselComponent },
      // { path: 'episodes/:id', component: EpisodeCarrouselComponent },
      { path: 'episodes/:id', component: EpisodeDetailComponent },
      { path: 'episodes/:id/:name', component: EpisodeDetailComponent },
      { path: 'editepisode/:episode_id', component: EpisodeFormComponent },
      { path: 'editepisode2/:episode_id', component: EpisodeEditComponent },

      { path: 'addphoto/:episode_id', component: UploaddialogComponent },
      //  { path: 'editepisode/:id', component: EpisodeEditComponent },
      { path: 'photos', component: PhotosComponent },
      { path: 'photos/new/:episode_id', component: UploaddialogComponent },
      { path: 'photos/:id', component: PhotoDetailComponent },
      { path: 'editphoto/:id', component: EditPhotoComponent },
      { path: 'textes', component: TextesComponent },
      // otherwise redirect to home
      { path: 'authors', component: AuthorsComponent },
      { path: 'authors/:id', component: AuthorDetailComponent },
      { path: 'authors/:id/:pseudo', component: AuthorDetailComponent },
      // { path: 'chatcli/:id', component: ChatcliComponent },
      // { path: 'chatcli', component: ChatcliComponent },
      { path: 'adpanel', component: AdpanelComponent },
      // { path: '**', redirectTo: 'home' }
      { path: 'subnotif', component: SubscribeToNotificationComponent }
    ],
  },
  // {
  //   path: '**',
  //   redirectTo: 'en/home',
  //   pathMatch: 'full'
  // },
  // { path: '**', component: Page404Component }
  { path: '**', component: DashboardComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}),
    // BlobModule.forRoot() // what for ??
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
